<template>
  <EntityList
    ref="activeList"
    :config="config"
    :columns="columns"
    :filter-vals.sync="filterVals"
    :filters="filters"
    :custom-query="{ questionnaire: parentEntity.id }"
    :formatted-columns="formattedColumns"
    :form-fields="formFields"
    authority="Questionnaire_Management_Questionnaire"
    embedded
    @entityUpdated="updateEntity"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './fieldsStoreModule'

export default {
  components: {
    EntityList,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    parentEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filterVals: [],
      entity: {},
      sectionOptions: [],
      typeOptions: [],
      unitOptions: [],
      fieldOptions: [],
      hasOptions: false,
      hasUnits: false,
      hasFunction: false,
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'questionnaire-fields',
        endpoint: 'questionnaire-fields',
        route: 'questionnaire-fields',
        title: {
          single: this.$t('Field Add'),
          plural: this.$t('Fields'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'type', label: this.$t('Type'), sortable: true },
        { key: 'section.title', label: this.$t('Section'), sortable: true },
        { key: 'required', label: this.$t('Required'), sortable: true },
        { key: 'ordering', label: this.$t('ordering'), sortable: true },
        {
          key: 'creationDate',
          label: this.$t('Creation Date'),
          sortable: true,
        },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'required', type: 'boolean' },
        { key: 'creationDate', type: 'datetime' }]
    },
    sizeOptions() {
      return [
        { id: 3, title: 3 },
        { id: 4, title: 4 },
        { id: 6, title: 6 },
        { id: 9, title: 9 },
        { id: 12, title: 12 },
      ]
    },
    filters() {
      return [
        {
          name: 'section',
          label: this.$t('Section'),
          options: this.sectionOptions,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          maxDateFieldIdx: '2',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          minDateFieldIdx: '1',
          maxDate: new Date(),
        },
      ]
    },
    formFields() {
      return [
        {
          id: 'questionnaire',
          type: 'hidden',
          initVal: { id: this.parentEntity.id },
          hidden: true,
        },
        {
          id: 'section',
          object: true,
          type: 'select',
          label: this.$t('Section'),
          options: this.sectionOptions,
          required: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
        },
        {
          id: 'helpText',
          type: 'text',
          label: this.$t('helpText'),
        },
        {
          id: 'type',
          type: 'select',
          label: this.$t('Type'),
          options: this.typeOptions,
          colSize: 6,
        },
        {
          id: 'size',
          type: 'select',
          label: this.$t('Size'),
          options: this.sizeOptions,
          required: true,
          initVal: 3,
          colSize: 6,
        },
        {
          id: 'options',
          type: 'selectmultiple',
          label: this.$t('Options'),
          options: [],
          taggable: true,
          pushTags: true,
          colSize: 12,
          hidden: !this.hasOptions,
        },
        {
          id: 'unit',
          object: true,
          type: 'select',
          label: this.$t('Q. Unit'),
          options: this.unitOptions,
          colSize: 4,
          hidden: !this.hasUnits,
        },
        {
          id: 'minValue',
          type: 'number',
          label: this.$t('min value'),
          colSize: 4,
          hidden: !this.hasUnits,
        },
        {
          id: 'maxValue',
          type: 'number',
          label: this.$t('max value'),
          colSize: 4,
          hidden: !this.hasUnits,
        },
        {
          id: 'virtualFunction',
          type: 'text',
          label: this.$t('Function'),
          colSize: 12,
          hidden: !this.hasFunction,
        },
        {
          id: 'dependentField',
          object: true,
          options: this.fieldOptions,
          type: 'select',
          label: this.$t('dependentField'),
        },
        {
          id: 'dependentFieldValue',
          type: 'text',
          label: this.$t('dependentFieldValue'),
          hidden: !this.entity.dependentField,
        },
        {
          id: 'ordering',
          type: 'text',
          label: this.$t('ordering'),
          required: true,
          rules: 'integer',
          initVal: 1,
          colSize: 3,
        },
        {
          id: 'hideable',
          type: 'checkbox',
          label: this.$t('hideable'),
          colSize: 3,
        },
        {
          id: 'required',
          type: 'checkbox',
          label: this.$t('Required'),
          initVal: true,
          colSize: 3,
        },
        {
          id: 'active',
          type: 'checkbox',
          label: this.$t('enabled'),
          initVal: true,
          colSize: 3,
        },
      ]
    },
  },
  mounted() {
    this.loaded = true
    this.$store
      .dispatch('app-common/fetchQuestionnaireSections', {
        questionnaire: this.parentEntity.id,
      })
      .then(response => {
        this.sectionOptions = response.data
      })
    this.$store.dispatch('app-common/fetchFieldTypes').then(response => {
      this.typeOptions = response.data.map(item => ({
        id: item,
        title: item,
      }))
    })
    this.$store
      .dispatch('app-common/fetchQuestionnaireUnits')
      .then(response => {
        this.unitOptions = response.data
      })
    this.$store.dispatch('app-common/fetchQuestionnairefieldsist', { questionnaire: this.parentEntity.id })
      .then(response => {
        this.fieldOptions = response.data
      })
  },
  methods: {
    updateEntity(newEntity) {
      this.entity = newEntity
      this.hasOptions = ['SELECT', 'RADIO', 'SELECTMULTIPLE'].includes(
        this.entity.type,
      )
      this.hasUnits = ['TEXT', 'NUMBER'].includes(this.entity.type)
      this.hasFunction = this.entity.type == 'VIRTUAL'
    },
  },
}
</script>
